var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { md: "12", lg: "7" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-justify-center" } }),
                  _c("strong", [_vm._v(" Bootstrap Carousel")]),
                  _c("div", { staticClass: "card-header-actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "card-header-action",
                        attrs: {
                          href:
                            "https://coreui.io/vue/docs/components/carousel",
                          rel: "noreferrer noopener",
                          target: "_blank"
                        }
                      },
                      [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("docs")
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "CCardBody",
                [
                  _c(
                    "CCarousel",
                    {
                      attrs: {
                        arrows: "",
                        indicators: "",
                        animate: "",
                        height: "400px"
                      }
                    },
                    [
                      _c("CCarouselItem", {
                        attrs: {
                          captionHeader: "First Slide",
                          image: "https://picsum.photos/1024/480/?image=52",
                          captionText:
                            "Nulla vitae elit libero, a pharetra augue mollis interdum."
                        }
                      }),
                      _c("CCarouselItem", {
                        attrs: {
                          captionHeader: "Blank page",
                          image: { placeholderColor: "grey" },
                          captionText:
                            "Nulla vitae elit libero, a pharetra augue mollis interdum."
                        }
                      }),
                      _c("CCarouselItem", {
                        attrs: {
                          image: "https://picsum.photos/1024/480/?image=54"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }